<template>
  <BaseCard>
    <template #content>
      <BaseDocuments
        :disabled="!isCanLoad($t('base.docs')) && !isCanInteractionPassport"
        :model-id="value.properties.id"
        accept-types="application/pdf, .docx, .doc, .xls, .xlsx, .jpg, .jpeg, .png"
        model-name="passport"
        v-model="detail"
        @input="$emit('input', $event)"
        name-object-doc="passportFiles"
        with-description
      />
    </template>
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/base/BaseCard'
import userInteraction from '@/components/mixins/userInteraction'
import BaseDocuments from '@/components/base/BaseDocuments'
import watchValue from '@/components/mixins/watchValue'

export default {
  name: 'PassportDocs',
  components: { BaseDocuments, BaseCard },
  mixins: [userInteraction, watchValue],
  props: {
    value: Object
  },
  data () {
    return {
      detail: this.value
    }
  }
}
</script>

<style scoped>

</style>
